








































































































































































import { PaginatedList } from '@/api/models/common/paginatedList';
import { DetailsUtilisateur } from '@/api/models/utilisateurs/utilisateur';
// import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import { BButton, BCard, BCol, BDropdown, BFormSelect, BDropdownItem, BFormInput, BPagination, BRow, BTable, BSpinner, BFormCheckbox, BAlert, VBTooltip } from 'bootstrap-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CreateUtilisateur from './Create.vue'

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    CreateUtilisateur,
    BFormSelect,
    BFormCheckbox
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class ListUtilisateurs extends Vue {
  loading = true;
  perPage = 10
  currentPage = 1
  perPageOptions = [2, 5, 10, 25, 50, 100]
  timeout: any = null;
  searchQuery=''
  hiddenUser = false;
  isSortDirDesc = false
  sortBy='nom'
  isAddNewUtilisateurSidebarActive = false

  tableColumns = [
    { key: 'nom', sortable: true },
    { key: 'prenom', sortable: true },
    { key: 'email', sortable: true },
    { key: 'telephone', sortable: false },
    { key: 'matricule', sortable: false },
  ]
  paginatedListUtilisateurs = new PaginatedList<DetailsUtilisateur>()

  async created(){
    await this.loadListUtilisateurs({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      hiddenUser: this.hiddenUser
    })
  }

  get paginationFrom() {
    return this.perPage * (this.currentPage-1) + (this.paginatedListUtilisateurs.items.length ? 1 : 0)
  }
  get paginationTo() {
    return this.perPage * (this.currentPage -1) + this.paginatedListUtilisateurs.items.length
  }


  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListUtilisateurs({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      hiddenUser: this.hiddenUser
    });
  }

  @Watch('searchQuery')
  @Watch("sortBy")
  @Watch("isSortDirDesc")
  @Watch("hiddenUser")
  async searchUtilisateur() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListUtilisateurs({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
        search: this.searchQuery,
        hiddenUser: this.hiddenUser
      })
    }, 1000);
  }

  async loadListUtilisateurs(params: any) {
    this.loading = true;
    if (!params || params?.reset) {
      this.paginatedListUtilisateurs = new PaginatedList<DetailsUtilisateur>()
    }

    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        params?.hiddenUser ?? this.hiddenUser,
        this.sortBy,
        this.isSortDirDesc,
        
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.paginatedListUtilisateurs = response;
        this.loading = false;
      });
  }

  onRowSelected(record: any) {
    this.$router.push({
      name: "edit-utilisateurs",
      params: { id: record.id },
    });
  }
}
