




























































































































































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { confirmed, password, required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { UtilisateurCreateModel } from "@/api/models/utilisateurs/utilisateur";
import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import { FonctionRessifnetFastt } from "@/api/models/ressifnetFastt/FonctionRessifnetFastt";
import appSettings from "@/appSettings";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "create-utilisateur",
})
export default class CreateUtilisateur extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewUtilisateurSidebarActive!: boolean;
  loading = false;

  required = required;
  password = password;
  confirmed = confirmed;
  isError = false;
  errorEmail= "";
  passwordFieldType = "password";

  listRessifnetFASTTFonction: FonctionRessifnetFastt[] = [];

  newUtilisateur: UtilisateurCreateModel = {};

  listFonctions : Fonction[] = [];
  totalCountFonction = 0;

  allowAddRessifnetFastt = appSettings.getAllowAddRessifnetFastt()

  async created() {
    await this.searchFonctions(null);
    await this.searchFonctionRessifnetFastt()
  }

  get passwordToggleIcon() {
    return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  }

  nameFormatter(value: string) {
    return value.toUpperCase();
  }

  async searchFonctions(params: any) {
    if (!params || params?.reset) {
      this.listFonctions = []
    }

    await this.$http.myressif.fonctions
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        
        this.listFonctions = this.listFonctions!.concat(
          response.items.filter(
            (ri) => !this.listFonctions!.some((lsi) => lsi.id == ri.id)
          )
        );
        this.totalCountFonction = response.totalCount;
      });
  }

  async searchFonctionRessifnetFastt(){
    await this.$http.ressifnetFastt.fonctions.listFonction()
      .then((response: FonctionRessifnetFastt[]) => {
        this.listRessifnetFASTTFonction = response
      })
  }

  resetForm() {
    this.newUtilisateur = {};
  }

  validateEmail() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.newUtilisateur?.email ?? ""
      )
    ) {
      this.isError = false;
    } else {
      this.isError = true;
      this.errorEmail = "Votre email est incorrect"
    }
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.utilisateurs
      .post(this.newUtilisateur)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un utilisateur",
            text: "Adhérent créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-adherent-sidebar-active", false);
        this.loading = false;
      });
  }

  togglePasswordVisibility() {
    if (this.passwordFieldType === "password") this.passwordFieldType = "text";
    else this.passwordFieldType = "password";
  }
}
